.we-headings {
    font-family: 'PunkRockerStamp-Regular', sans-serif !important;
    font-size: 8rem;
}

// .home-service-container {
//     margin: 0;
//     position: absolute;
//     top: 50%;
//     -ms-transform: translateY(-50%);
//     transform: translateY(-50%);
// }

.home-service-container {
    margin: 0;
    position: relative; /* Change to relative */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    height: 100%; /* Make sure it takes the full height of the parent */
}

.contact-us-headline {
    font-family: 'PunkRocker-Regular', sans-serif;
    font-size: 5.5rem;
}

.contact-us-headline-container {
    margin: 0;
    position: absolute;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 5rem;
    margin-right: -5rem;
}

@media (max-width: 480px) {
    .contact-us-headline-container {
        margin-left: auto ;
        margin-right: auto;
        position: static; /* Remove absolute positioning */
        margin-left: auto;
        margin-right: auto;
        text-align: center; /* Center text content */
        transform: none;
    }
}

.video-container {
    margin-top: -10rem !important;
}

@media  (max-width:480px) {
    .video-container {
        margin-top: 0 !important;
    }
}


