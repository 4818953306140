@import url("https://use.typekit.net/jsi1vnn.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@font-face {
    font-family: 'BebasNeue-Regular';
    src: url(../fonts/BebasNeue-Regular.woff) format('woff');
}
@font-face {
    font-family: 'Obscura-Regular';
    src: url(../fonts/Obscura-Regular.woff) format('woff');
}
@font-face {
    font-family: 'PunkRocker-Regular';
    src: url(../fonts/PunkRocker-Regular.woff) format('woff');
}
@font-face {
    font-family: 'PunkRockerRough-Regular';
    src: url(../fonts/PunkRockerRough-Regular.woff) format('woff');
}
@font-face {
    font-family: 'PunkRockerStamp-Regular';
    src: url(../fonts//PunkRockerStamp-Regular.woff) format('woff');
}
@import './custom.scss';
// eslint-disable-next-line
@import './home-page.scss';
@import '../../node_modules/bootstrap/scss/bootstrap';