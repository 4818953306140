$primary: #FF00B8;
$secondary: #eeeeee;
$dark: #101519;

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    dark: $dark
);

// @font-face {
//     font-family: 'BebasNeue-Regular';
//     src: url(../fonts/BebasNeue-Regular.woff) format('woff');
// }

.main {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    background-image: url("../images/assets/PB_Web_BG.jpg");
}

.nav-logo {
    width: 100%;
}

.nav-font {
    font-size: 2rem !important;
    font-family: 'BebasNeue-Regular', sans-serif;
}

.nav-lin:visited {
    color: $primary;
    text-decoration: underline !important;
}

@media (max-width: 480px) {
    .main-nav {
        display: none !important;
    }

    .nav-logo {
        width: 50%;
    }

    .nav-font {
        font-size: 1.5rem !important;
    }

}

@media (min-width: 480px) {
    .mobile-nav {
        display: none !important;
    }
}

.video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.font-primary {
    font-family: 'BebasNeue-Regular', sans-serif;
}

.body-font {
    font-family: "brother-1816", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.font-secondary {
    font-family: 'PunkRocker-Regular', sans-serif;
}

.filter-primary {
    filter: invert(100%) sepia(69%) saturate(80%) hue-rotate(230deg) brightness(112%) contrast(87%);
}

.btn-main {
    border: 2px solid $primary;
    font-family: 'PunkRocker-Regular', sans-serif;
    font-size: 3rem;
    background-color: transparent;
    padding: 0px 60px;
    color: #ff00b8;
}

.input-text {
    border-radius: 0;
    border: 2px solid $primary;
    background-color: $secondary;
}

.input-text:focus {
    border-radius: 0 !important;
    border: 2px solid $primary !important;
    background-color: $secondary !important;
    outline: 2px solid $primary;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
}

.font-punk-stamp {
    font-family: 'PunkRockerStamp-Regular', sans-serif;
}

.cereal-animation {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    50% { -moz-transform: rotate(20deg); } 
    50% { -moz-transform: rotate(-20deg); }
}
@-webkit-keyframes spin { 
    50% { -webkit-transform: rotate(20deg); } 
    50% { -webkit-transform: rotate(-20deg); } 
}
@keyframes spin { 
    50% { 
        -webkit-transform: rotate(20deg); 
        transform:rotate(20deg); 
    }

    50% { 
        -webkit-transform: rotate(-20deg); 
        transform:rotate(-20deg); 
    } 
}

.contact-headline {
    font-size: 10rem;
}

@media (max-width:480px) {
    .contact-headline {
        font-size: 5rem;
    }
}